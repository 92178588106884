var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sync-wrapper"},[_c('Block',{staticClass:"sync-block"},[_c('div',{staticClass:"sync-logo"},[_c('Logo')],1),(!_vm.isSyncing && !_vm.isDone)?_c('div',{staticClass:"sync-desc"},[_vm._v("Для работы приложения требуется получение данных из Битрикс24 о пользователях и сущностях CRM")]):_vm._e(),_c('div',{staticClass:"sync-alert"},[(_vm.isDone)?_c('Alert',{attrs:{"icon":"success"}},[_vm._v("Синхронизация успешно завершена")]):(_vm.error)?_c('Alert',{attrs:{"icon":"danger"}},[_vm._v(_vm._s(_vm.error))]):_c('Alert',{attrs:{"icon":"warning"}},[_vm._v("Не закрывайте страницу до окончания синхронизации")])],1),(_vm.isSyncing)?_c('div',{staticClass:"sync-bar"},[_c('Bar',{attrs:{"max":_vm.total,"bars":[
          {
            start: 0,
            stop: _vm.count,
            class: 'text-right',
            background: 'var(--green-color)',
            'z-index': 10,
            title: 'Прогресс'
          }
        ]}}),(_vm.total)?_c('div',{staticClass:"sync-bar-desc"},[_c('span',[_vm._v(_vm._s(_vm.count)+" из "+_vm._s(_vm.total))]),_vm._v(" - "+_vm._s(_vm.status))]):_vm._e()],1):_vm._e(),(!_vm.isSyncing)?_c('div',{staticClass:"sync-btn"},[(_vm.isDone)?_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.done}},[_vm._v("Перейти к настройке")]):(_vm.error)?_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.restart}},[_vm._v("Попробовать еще раз")]):(!_vm.isSyncing)?_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.start}},[_vm._v("Запустить синхронизацию")]):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }