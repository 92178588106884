<template>
  <div class="cross">
    <svg width="10" height="10"><use :xlink:href="crossSvg + '#icon'"></use></svg>
  </div>
</template>

<script>
import crossSvg from '@/assets/svg/cross_icon.svg'

export default {
  name: 'CrossIcon',
  data () {
    return {
      crossSvg
    }
  }
}
</script>

<style scoped>
.cross {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: var(--text-color);
  border-radius: 7px;
  cursor: pointer;
  border: 1px solid var(--bg-color);
}
.block.grey .cross {
  background: var(--bg-color);
  border-color: var(--border-color);
}
.cross:hover svg, a:hover .cross svg {
  color: var(--title-color);
}
</style>
