<template>
  <div
    class="bar"
    :class="{ 'bar-small': small }"
  >
    <div class="bar-progress bar-progress__bg">
      <div
        v-if="max > 0"
        class="bar-pointer"
        :class="{
          'min-value': pointerOffset <= 8,
          'max-value': pointerOffset >= 92
        }"
      >
        <div
          class="bar-pointer-text chart-label"
          :style="`left: ${pointerOffset}%;`"
        >
          <slot name="pointer-text"></slot>
        </div>
        <div
          v-if="pointerCursor"
          class="bar-pointer-cursor"
          :style="`left: ${pointerOffset}%;`"
          :title="`${pointerOffset}%`"
        >
          <slot name="pointer-cursor">
            <div v-if="small" class="pointer-rounded small"></div>
            <div v-else class="pointer-rounded small top"></div>
          </slot>
        </div>
      </div>
      <div
        v-if="pointerTbuCursor && pointerTbuOffset > 0 && min_sum_mode"
        v-b-tooltip.hover.top.html
        :title="tbuTitle"
        class="bar-pointer__tbu"
        :style="`left: ${pointerTbuOffset}%`"
      ></div>
      <transition-group
        v-for="(bar, index) in bars"
        :key="index"
        appear
        appear-class="bar-fill-start"
      >
        <div
          v-if="barPercent(max, bar.start, bar.stop) > 0"
          class="bar-progress"
          :class="[bar.class, {'cursor-pointer': bar.balloon}]"
          :style="`
            background: ${barBackground(bar.background, barPercent(max, bar.start, bar.stop), pointerOffset)};
            width: ${barPercent(max, bar.start, bar.stop)}%;
            z-index: ${bar['z-index']};
            left: ${barOffset(max, bar.start)}%;
          `"
          key="fill"
          :title="`${barTitle(bar.title, barPercent(max, bar.start, bar.stop))}`"
        >
          <span v-if="!small && !barOffset(max, bar.start)" class="bar-progress__percent">
            {{ barPercent(max, bar.start, bar.stop) }}%
          </span>
          <div class="bar-progress__labels" v-if="bar.labels">
            <span class="rx-title currency">{{ bar.labels.min | money }}</span>
            <span class="rx-text font-weight-normal">{{ bar.labels.middle }}</span>
            <span class="rx-title currency">{{ bar.labels.max | money }}</span>
          </div>
          <div class="bar-progress__balloon" v-if="bar.balloon">
            <div class="balloon__wrapper">
              <div class="balloon__head" v-if="bar.title">
                <span
                  class="dot-icon mr-10"
                  :style="`background: ${barBackground(bar.background, barPercent(max, bar.start, bar.stop), pointerOffset)};`"
                ></span>
                <span class="balloon__title">{{ bar.title }}</span>
              </div>
              <div class="balloon__body">
                <div class="balloon__result-price">
                  <span class="currency rx-title font-weight-bold">{{ bar.fact | money }}</span> из <span class="currency font-weight-bold">{{ bar.salesFact | money }}</span>
                </div>
                <div class="balloon__result-percent" v-if="bar.factPercent">
                  {{ bar.salesFactPercent }}% - от общих продаж
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
    <slot name="label-after"></slot>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'Bar',
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    pointerValue: {
      type: Number,
      default: 100
    },
    bars: {
      type: Array,
      default: () => []
    },
    small: {
      type: Boolean,
      default: false
    },
    pointerCursor: {
      type: Boolean,
      default: false
    },
    pointerOffset: {
      type: Number,
      default () {
        const lastDay = moment().endOf('month').format('D') - 1
        const curDay = moment().format('D') - 1

        return Math.floor(curDay / lastDay * 100)
      }
    },
    pointerTbuCursor: {
      type: Boolean,
      default: false
    },
    pointerTbuValue: {
      type: Number,
      default: 0
    },
    pointerTbuOffset: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState('sale/settings', ['min_sum_mode']),
    ...mapGetters('sale/date', ['isPresentMonth']),
    tbuTitle () {
      let result = 'Точка безубыточности'
      if (this.pointerTbuValue > 0) {
        result += ` <span class='currency'>${this.$options.filters.money(this.pointerTbuValue)}</span>`
      }

      let fact = 0
      if (this.bars !== undefined) {
        const factBar = this.bars.find(el => el.background.indexOf('multicolor') > -1)

        if (factBar !== undefined) {
          fact = factBar.stop

          if (this.pointerTbuValue - fact > 0) {
            result += `.<br>Осталось <span class='currency'>${this.$options.filters.money(this.pointerTbuValue - fact)}</span>`
          }
        }
      }
      return result
    },
    barsCount () {
      return this.bars.length
    }
  },
  async created () {
    await this.$store.dispatch('sale/settings/fetchSettings')
  },
  methods: {
    barPercent (maxValue, startValue, stopValue) {
      const stopPercent = stopValue && maxValue ? Math.floor(stopValue / maxValue * 100) : 0
      return stopPercent - this.barOffset(maxValue, startValue)
    },
    barOffset (maxValue, startValue) {
      return startValue && maxValue ? Math.floor(startValue / maxValue * 100) : 0
    },
    barTitle (title, percent) {
      let result = ''

      if (title) {
        result = title + ' ' + percent + '%'
      } else {
        result = percent + '%'
      }

      return result
    },
    barBackground (color = 'var(--green-color)', percent = 50, need = 100) {
      if (color !== 'multicolor') {
        return color
      }

      return this.$options.filters.barcolor(percent, need)
    }
  }
}
</script>

<style scoped>
.bar {
  width: 100%;
}
.bar-progress {
  border-radius: 20px;
  height: 20px;
}
.bar-progress {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  padding-right: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  transition: width 1.5s ease-in-out;
  max-width: 100%;
}
.bar.bar-small .bar-progress {
  line-height: 10px;
  height: 10px;
}
.bar-progress.bar-progress__bg {
  width: 100%;
  position: relative;
  background: var(--bg-color);
  left: auto;
  top: auto;
  padding-right: 0;
}
.bar-fill-start {
  width: 0 !important;
}
.bar-pointer-text {
  position: absolute;
  bottom: calc(100% + 22px);
  width: 200px;
  text-align: center;
  margin-left: -100px;
  font-size: 13px;
  line-height: 1.3;
  margin-bottom: 0;
  font-weight: 400;
  color: var(--text-color);
}
.bar.bar-small .bar-pointer-text {
  bottom: 100%;
}
.bar-pointer.min-value .bar-pointer-text {
  margin-left: 0;
  text-align: left;
}
.bar-pointer.max-value .bar-pointer-text {
  margin-left: -200px;
  text-align: right;
}
.bar-pointer-cursor {
  position: absolute;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
}
.bar-progress__labels {
  position: relative;
  top: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.bar-progress__label {
  /* font-size: 12px; */
  color: var(--text-color);
  margin-top: 15px;
  font-weight: 600;
}
.bar-small .bar-progress__label {
  font-size: 12px;
  margin-top: 5px;
}

/* pointers */
.pointer-rounded {
  background: #fff;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 50%;
  border: 5px solid var(--green-color);
  margin-left: -8px;
}
.bar-pointer.min-value .pointer-rounded {
  margin-left: 0;
}
.bar-pointer.max-value .pointer-rounded {
  margin-left: -8px;
}
.pointer-rounded.small {
  background: var(--red-color);
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  margin-left: -4px;
}
.bar-pointer.max-value .pointer-rounded.small {
  margin-left: -8px;
}
.pointer-rounded.small.top {
  top: -10px;
}
.bar.small .pointer-rounded.small.top {
  top: -5px;
}
.pointer-rounded:not(.small)::after {
  content: '';
  width: 2px;
  height: 21px;
  background: var(--border-color);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(-100% - 20px);
  z-index: -1;
}
.pointer-rounded.small.top::after {
  content: '';
  width: 2px;
  height: 21px;
  background: var(--border-color);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(-100% - 15px);
  z-index: -1;
}
.bar-pointer__tbu {
  background: var(--title-color);
  width: 6px;
  border: 2px solid #fff;
  height: calc(100% + 8px);
  position: absolute;
  z-index: 100;
  top: -4px;
}
/* balloon */
.bar-progress__balloon {
  display: none;
  position: absolute;
  bottom: 100%;
  padding-bottom: 6px;
  right: 50%;
  transform: translateX(50%);
  width: 230px;
  min-height: 110px;
  border-radius: 5px;
  cursor: default;
}
.bar-progress.bar-progress__bg .bar-progress:hover .bar-progress__balloon {
  display: block;
}
.balloon__wrapper {
  padding: 15px 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(185, 195, 216, 0.5);
}
.balloon__head {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}
.balloon__title {
  color: var(--title-color);
  font-weight: 600;
  font-size: 13px;
}
.balloon__body {
  margin-top: 10px;
  color: var(--text-color);
}
.balloon__result-price {
  font-size: 13px;
}
.balloon__result-percent {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
}
</style>
