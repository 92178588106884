<template>
  <div class="sync-wrapper">
    <Block class="sync-block">
      <div class="sync-logo"><Logo /></div>
      <div v-if="!isSyncing && !isDone" class="sync-desc">Для работы приложения требуется получение данных из Битрикс24 о пользователях и сущностях CRM</div>
      <div class="sync-alert">
        <Alert v-if="isDone" icon="success">Синхронизация успешно завершена</Alert>
        <Alert v-else-if="error" icon="danger">{{ error }}</Alert>
        <Alert v-else icon="warning">Не закрывайте страницу до окончания синхронизации</Alert>
      </div>
      <div v-if="isSyncing" class="sync-bar">
        <Bar
          :max="total"
          :bars="[
            {
              start: 0,
              stop: count,
              class: 'text-right',
              background: 'var(--green-color)',
              'z-index': 10,
              title: 'Прогресс'
            }
          ]"
        />
        <div class="sync-bar-desc"  v-if="total"><span>{{ count }} из {{ total }}</span> - {{ status }}</div>
      </div>
      <div v-if="!isSyncing" class="sync-btn">
        <button v-if="isDone" class="btn btn-primary" @click="done">Перейти к настройке</button>
        <button v-else-if="error" class="btn btn-primary" @click="restart">Попробовать еще раз</button>
        <button v-else-if="!isSyncing" class="btn btn-primary" @click="start">Запустить синхронизацию</button>
      </div>
    </Block>
  </div>
</template>

<script>
import Logo from '@/components/ui/Logo'
import Alert from '@/components/ui/Alert'
import Bar from '@/components/sale/Bar'

export default {
  name: 'Sync',
  components: {
    Logo,
    Alert,
    Bar
  },
  props: {
    entities: Array
  },
  data () {
    return {
      isSyncing: false,
      isDone: false,
      status: '',
      error: '',
      count: 0,
      total: 0
    }
  },
  methods: {
    async start () {
      this.isSyncing = true

      const entityName = {
        department: 'подразделений',
        user: 'пользователей',
        lead: 'лидов',
        pipeline: 'направлений сделок',
        stage: 'стадий сделок',
        deal: 'сделок',
        call: 'звонков',
        activity: 'дел за последние 30 дней'
      }

      for (const i in this.entities) {
        const entity = this.entities[i]
        await this.$store.dispatch('integration/bitrix24/sync', {
          entity,
          start: () => {
            this.count = 0
            this.total = 0
          },
          step: ({ count, total }) => {
            this.count = count
            this.total = total
            this.status = 'синхронизировано ' + entityName[entity]
          },
          error: err => {
            this.error = err
            this.isSyncing = false
          },
          finish: () => {
            return new Promise(resolve => setTimeout(resolve, 1000))
          }
        })
      }

      if (!this.error) {
        this.isSyncing = false
        this.isDone = true
      }
    },
    restart () {
      this.isSyncing = false
      this.isDone = false
      this.error = ''
      this.status = ''
    },
    async done () {
      this.$emit('done')
    }
  }
}
</script>

<style scoped>
.sync-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sync-block {
  width: 800px;
}
.sync-logo {
  margin-bottom: 30px;
}
.sync-desc {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--title-color);
  margin-top: 10px;
  margin-bottom: 25px;
}
.sync-alert {
  margin-top: 10px;
}
.sync-bar {
  margin-top: 20px;
}
.sync-bar-desc {
  margin-top: 15px;
  font-size: 13px;
  line-height: 15px;
  color: var(--text-color);
}
.sync-bar-desc span {
  color: var(--title-color);
  font-weight: 600;
}
.sync-btn {
  margin-top: 30px;
}
.sync-status {
  text-align: center;
}
</style>
