<template>
  <div class="alert">
    <div class="alert-icon">
      <SpriteIcon :icon="icon" />
    </div>
    <div class="alert-content ml-15">
      <slot></slot>
    </div>
    <div @click="closeAlert()">
      <CrossIcon v-if="close !== false" />
    </div>
  </div>
</template>

<script>
import CrossIcon from '@/components/ui/icons/CrossIcon'

export default {
  name: 'Alert',
  components: {
    CrossIcon
  },
  props: {
    icon: {
      type: String, // danger, warning, success
      default: 'danger'
    },
    close: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeAlert () {
      this.$emit('closeAlert')
    }
  }
}
</script>

<style>
.alert {
  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
}
.alert-content {
  width: 100%;
  margin-top: 3px;
}
.alert .cross {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
