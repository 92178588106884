<template>
  <div class="logo">
    <img :src="require('@/assets/img/logo.png')" alt="Bigame">
    <span>Bigame</span>
  </div>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style>
.logo {
  display: flex;
  align-items: center;
}
.logo img {
  display: block;
  margin-right: 10px;
}
.logo span {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: var(--title-color);
}
</style>
